import { PreprocessUserMessageReturnType, useSendAnythingToServer } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatEditorRefFunctions } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { useContext } from "react";
import { uniq } from "lodash";
import { NotesContext } from "../../../Notes/Data/NotesContext";
import { Note } from "../../../Notes/Data/NoteType";
import { useLinkToNote_ForEditor } from "../../Utilities/NavigateTo";
import { htmlToMarkdown } from "../../../ServerConnection/LLMServer/MarkdownConverter";
import { estimateTokensFromString } from "../../../ServerConnection/LLMServer/LLMTokenCounter";
import useTopicDetectionV7Command, { getTopicInputV7FromMentionables, getTopicInputV7FromNotes } from "../../../ServerConnection/LLMServer/TopicDetectionAIV7";
import { ChatLogContext } from "./ChatLog";
import { LLMServerContext } from "./SelectableLLM";
import { ChatWithDraft } from "./ChatWithDraft";
import { TemplateDetectionReturnType, detectTemplate } from "../../../ServerConnection/LLMServer/DetectTemplateV8";
import { getNoteTypeWithSchemaFor } from "../../../Extensions/ExtensionsFramework/ExtensionsSchemas";
import { getJustThisNoteHTML } from "../../../Notes/UIs/NoteInformationComponents/DocReferences";
import { NoteHierarchyContext } from "../../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider";
import { NoteHierarchy } from "../../../Notes/UIs/NoteInformationComponents/NoteHierarchy";
import { isATemplateOrType } from "../../../Extensions/ExtensionsFramework/IsATemplate";
import { PinnedJSONFormsContext, SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { Extension, ExtensionNoteTemplateType } from '../../../Extensions/ExtensionsFramework/ExtensionsList';
import { getJSONFormsObject } from '../../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject';
import { useFirestore } from 'reactfire';

const DEBUG = false;

const USE_TEMPLATE_DETECTION_V8 = true;

export const USE_DETECTION_V9=true;


type NotePrompt = {
    // From note:
    doc_name: string;
    doc_id: string;
    type: string;
    emoji: string;

    // Converted from note:
    markdownPrompt: string;

    // Context about how it was mentioned:
    isTemplate: boolean;
}

export default function ChatEditorWithNotePreprocessing({chatEditorRef}:{chatEditorRef:React.MutableRefObject<ChatEditorRefFunctions>}) {
    const notesContext = useContext(NotesContext);
    const firestore = useFirestore();
    const {selectedChatMessages} = useContext(ChatLogContext);
    const {serverType} = useContext(LLMServerContext);
    const linkToNote = useLinkToNote_ForEditor();

    const topicDetectionV7Command = useTopicDetectionV7Command();
    const {sendToServerWithStreaming} = useSendAnythingToServer();
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const pinnedJSONFormsContext = useContext(PinnedJSONFormsContext);
    let jsonFormsContext = pinnedJSONFormsContext;
    if (!pinnedJSONFormsContext.hasNote) {
        jsonFormsContext = selectedJsonFormsContext;
    }


    function getUserInput() {
        const userMessages = selectedChatMessages.filter((message) => message.role === "user" && message.content);
        if (userMessages.length===0) {
            console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: No user messages found. This should not happen.");
            debugger;
        }
        const lastUserMessage = userMessages[userMessages.length-1];
        const userInput = lastUserMessage.content as string;
        if (!userInput) {
            console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: No user input found. This should not happen.");
            debugger;
        }
        return userInput;
    }
    function getUniqMarkdownSystemPrompt(systemPrompts:NotePrompt[]) {
        return uniq(systemPrompts.map(({markdownPrompt}) => markdownPrompt)).join("\n\n").trim();
    }

    const {topLevelNoteHierarchy, extensions} = useContext(NoteHierarchyContext);
    // const preprocessUserMessageV9:PreProcessUserMessageFuncType =
    async function preprocessUserMessageV9()/*:Promise<PreprocessUserMessageReturnType>*/  {
        if (!topLevelNoteHierarchy)
            return {} as PreprocessUserMessageReturnType;
        while (!topLevelNoteHierarchy.isLoaded) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }
        const userInput = getUserInput();
        const templatesWithSchemas = [] as ExtensionNoteTemplateType<any>[];
        const numObjsWithTemplateSchema = [] as number[];
        async function loadAndReturnNoteMarkdownV9(note:Note, isTemplate:boolean):Promise<NotePrompt> {
            const html = await getJustThisNoteHTML(note, notesContext, linkToNote, extensions);
            let markdownPrompt = htmlToMarkdown(html);
            if (isTemplate) {
                const noteTemplateType = getNoteTypeWithSchemaFor(extensions, note);
                if (noteTemplateType && noteTemplateType.schema) {
                    templatesWithSchemas.push(noteTemplateType);
                    numObjsWithTemplateSchema.push(0);
                    const schema = noteTemplateType?.schema;
                    const schemaString = "# Schema for "+note.doc_name+"\n\n```json\n"+JSON.stringify(schema)+"\n```";
                    if (markdownPrompt.trim().length>0)
                        markdownPrompt = markdownPrompt+"\n\n"+schemaString;
                    else
                        markdownPrompt = schemaString;              
                }
            } else if (note.template_doc_id) {
                // Check if this note has a template that's in our list of templates with schemas:
                const templateIndex = templatesWithSchemas.findIndex((template:ExtensionNoteTemplateType<any>) => template.template_doc_ids?.includes(note.template_doc_id!));
                const template = templatesWithSchemas[templateIndex];                
                if (template) {
                    // Include the JSON from this note in the markdown.
                    const obj = await getJSONFormsObject(firestore, note.id);
                    if (obj) {
                        numObjsWithTemplateSchema[templateIndex]++;
                        const schemaString = "\n\n```json\n"+JSON.stringify(obj)+"\n```";
                        if (markdownPrompt.trim().length>0)
                            markdownPrompt = markdownPrompt+"\n\n"+schemaString;
                        else
                            markdownPrompt = schemaString;              
                    }
                }
            }
            return {doc_name:note.doc_name, markdownPrompt, doc_id:note.id, emoji:note.emoji, isTemplate, type: note.type} as NotePrompt;
        }

        ///////////////////////////////////////
        // Parse the hierarchy to get the list of always inclued, and mentionables.
        const alwaysIncludedIDs = [] as string[];
        const mentionableTopicsIDs = [] as string[];
        const mentionableTemplatesIDs = [] as string[];
        function parseNode(noteHierarchy:NoteHierarchy, isAlwaysIncluded:boolean) {
            if (!topLevelNoteHierarchy)
                throw new Error("This line is not possible to reach, but TypeScript seems to require it.");
            if (isAlwaysIncluded) {
                alwaysIncludedIDs.push(noteHierarchy.note.id);
                for (const noteID of noteHierarchy.fullyIncludedNoteIDs) {
                    const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
                    if (!nextNoteHierarchy) {
                        debugger;
                        continue;
                    }
                    parseNode(nextNoteHierarchy, true);
                }
                for (const noteID of noteHierarchy.linkedNoteIDs) {
                    const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
                    if (!nextNoteHierarchy) {
                        debugger;
                        continue;
                    }
                    parseNode(nextNoteHierarchy, false);
                }
                for (const noteID of noteHierarchy.editContextNotes) {
                    const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
                    parseNode(nextNoteHierarchy, false);
                }
            } else if (isATemplateOrType(noteHierarchy.note))
                mentionableTemplatesIDs.push(noteHierarchy.note.id);
            else
                mentionableTopicsIDs.push(noteHierarchy.note.id);
        }
        for (const noteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
            parseNode(noteHierarchy, true);
        }

        ///////////////////////////////////////
        // Template detection
        // Start parallel processing
        const mentionableTemplates = mentionableTemplatesIDs.map((id:string) => topLevelNoteHierarchy.noteHierarchiesById[id].note);
        let templateDetectionPromise = null as Promise<TemplateDetectionReturnType>|null;
        if (serverType.supportsFunctions && USE_TEMPLATE_DETECTION_V8) {
            templateDetectionPromise = detectTemplate(mentionableTemplates, userInput, sendToServerWithStreaming);
            if (DEBUG) console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> calling template detection V8 API with ",mentionableTemplates, userInput);
        }

        ///////////////////////////////////////
        // Topic detection
        // Start parallel processing
        const mentionableTopicNotes = mentionableTopicsIDs.map((id:string) => topLevelNoteHierarchy.noteHierarchiesById[id].note);
        let topicsInputV7 = getTopicInputV7FromNotes(jsonFormsContext.note, jsonFormsContext.jsonSchemaNote);
        topicsInputV7 = getTopicInputV7FromMentionables(mentionableTopicNotes, topicsInputV7);
        topicsInputV7.userInput = userInput;
        console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> calling topics detection V7 API with ",topicsInputV7);
        const topicDetectionPromise = topicDetectionV7Command(topicsInputV7);

        ///////////////////////////////////////
        // Finish
        const promptPromises = [] as Promise<NotePrompt>[];
        const messageProcessingExtraInfo = [] as string[];
        let outputOfTopicsThinking = "";


        ///////////////////////////////////////
        // Finish template detection
        // (finish first because it will usually execute faster)
        let templateNote = undefined as Note|undefined;
        let isEditingTemplate = false;
        {
            let templateDetected = null as TemplateDetectionReturnType|null;
            if (templateDetectionPromise) {
                templateDetected = await templateDetectionPromise;
                console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> template v8 detection templateDetected is ",templateDetected);
                if (templateDetected && templateDetected.type!=="none") {
                    // Make sure it's a real note:
                    templateNote = mentionableTemplates.find((note:Note) => note.doc_name === templateDetected?.template);
                    if (!templateNote) {
                        console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Template detected, but the note wasn't found in the mentionable notes. This should not happen.");
                        // debugger;
                    } else {
                        // Put the template first.
                        promptPromises.unshift(loadAndReturnNoteMarkdownV9(templateNote,true));
                        if (templateDetected.type==="edit")
                            isEditingTemplate=true;
                    }
                }
            }
        }

        ///////////////////////////////////////
        // Finish topic detection
        {
            const topicsOutputV7 = await topicDetectionPromise;
            if (!topicsOutputV7.succeeded) {
                debugger;
            } else if (topicsInputV7.topics) {
                if (DEBUG) console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> topicsOutputV7 is ",topicsOutputV7);
                for (const topic of topicsOutputV7.topics as string[]) {
                    let notes = mentionableTopicNotes.filter((note:Note) => note.doc_name === topic);
                    if (notes.length===0) {
                        // It seems common that it gives us topics with extra spaces in the name before and after.
                        notes = mentionableTopicNotes.filter((note:Note) => note.doc_name === topic.trim());
                        if (notes.length===0) {
                            // TODO we might check the full hierarchy for this topic that the user mentioned.
                            console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> Warning: No notes found for topic: '"+topic+"'.");
                            continue;
                        }
                    }
                    // This is in the notesMentionable list, and it's a topic. So we load it and process it.
                    const promptPromise = loadAndReturnNoteMarkdownV9(notes[0], false);
                    promptPromises.push(promptPromise);
                }
                outputOfTopicsThinking+=" "+topicsOutputV7.logic;
            }
        }


        ///////////////////////////////////////
        // Assemble prompts
        const systemPrompts = [] as NotePrompt[];
        // Add all the fully included ones:
        for (const noteID of alwaysIncludedIDs) {
            const note = topLevelNoteHierarchy.noteHierarchiesById[noteID].note;
            const prompt = await loadAndReturnNoteMarkdownV9(note, false);
            if (prompt && systemPrompts.findIndex((notePrompt:NotePrompt) => notePrompt.doc_id === prompt.doc_id)<0)
                systemPrompts.push(prompt);
        }
        // Then add in all the ones that were loaded from the template & topics:
        const promptResults = (await Promise.all(promptPromises)).filter((result:NotePrompt|null) => result!==null) as NotePrompt[];
        promptResults.forEach((prompt:NotePrompt, index:number) => {
            if (prompt && systemPrompts.findIndex((notePrompt:NotePrompt) => notePrompt.doc_id === prompt.doc_id)<0)
                systemPrompts.push(prompt);
        });

        // If there's too much in the context, 
        let tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(systemPrompts));
        while (tokensUsed>serverType.contextLength-5000) {
            // Remove system prompts from last to first, until we have some space left.
            const removedPrompt = systemPrompts.pop();
            if (!removedPrompt) {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: We ran out of prompts to remove, but we still have too many tokens.");
                break;
            }
            tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(systemPrompts));
        }
        // Now add all of them except the first one (which was the default, created above)
        // const refs = systemPrompts.map(({doc_name}) => "📄 ["+doc_name+"](</note/"+mentionableNotes.find((note:Note) => note.doc_name === doc_name)?.id+">) ").join(" ");
        // TODO reduce this down to the just the optional refs, maybe?
        let noteIDs = uniq(systemPrompts.map(({doc_id}) => doc_id));
        // If there's a template, make that first in the list of references.
        if (templateNote) {
            //@ts-ignore
            noteIDs = noteIDs.filter((id:string) => id!==templateNote.id);
            noteIDs.unshift(templateNote.id);
            
            for (let i=0; i<templatesWithSchemas.length; i++) {
                const numObs = numObjsWithTemplateSchema[i];
                const template = templatesWithSchemas[i];
                
                if (numObs<3 && tokensUsed<serverType.contextLength-5500) {
                    console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> We have only ",numObjsWithTemplateSchema[0]," examples objects for '"+template.name+"'. We should load more.");
                    // First check if there are any more of these in memory already. We can check notesContext.notes.
                    const loadedNotes = notesContext.getLoadedNotesOfTemplate(template)
                    let addedNotes = 0;
                    for (const note of loadedNotes) {
                        if (systemPrompts.filter((notePrompt:NotePrompt) => notePrompt.doc_id === note.id).length>0)
                            continue;
                        if (tokensUsed>serverType.contextLength-5000)
                            break;
                        systemPrompts.push(await loadAndReturnNoteMarkdownV9(note, false));
                        tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(systemPrompts));
                        addedNotes++;
                        if (addedNotes>=3)
                            break;    
                    }
                }
            }
        }
        let systemPrompt = getUniqMarkdownSystemPrompt(systemPrompts);
        if (isEditingTemplate) {
            const EDIT_INCLUDE = "When editing an object's data: (1) Begin with the title using H1 (# Object Title). (2) Reprint the full object's JSON. Do not print changes only, but rather, include the full JSON.";
            systemPrompt = EDIT_INCLUDE+"\n\n"+systemPrompt;
        }
        return {systemPrompt, messageProcessingExtraInfo, outputOfTopicsThinking, noteIDs} as PreprocessUserMessageReturnType;
    }

    
    chatEditorRef.current.preProcess_UserMessage = preprocessUserMessageV9;

    return <>
        <div id="chatWithDraftHolder" style={{overflowY:"scroll",height:"calc(100vh - 45px)"}}>
            <ChatWithDraft chatEditorRef={chatEditorRef}/>
        </div>
    </>;
}
