import { Button, Progress, Segmented, Spin, Table, TableProps, Tooltip } from "antd";
import { useContext, useMemo } from "react";
import { useLinkToNote, useNavigateToNote } from "../../../DecisionGraph/Utilities/NavigateTo";
import { NoteHierarchy, TopLevelNoteHierarchy } from "./NoteHierarchy";
import { NoteHierarchyContext } from "./NoteHierarchyProvider";
import { getNoteNameAndEmoji_multipurpose } from "../../NotesTree/TreeUtilities/CreateTagTreeNodes";
import { NotesContext, NotesContextType } from "../../Data/NotesContext";
import { useStateLSString } from "../../../DecisionGraph/Utilities/StateWithLocalCache";
import { ApartmentOutlined, AppstoreOutlined, CheckCircleOutlined, CopyOutlined, PushpinFilled, PushpinOutlined, SaveOutlined, SelectOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { isATemplateOrType } from "../../../Extensions/ExtensionsFramework/IsATemplate";
import { SegmentedOptions } from "antd/es/segmented";
import { PinnedJSONFormsContext, SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { OPERATION_COMPLETE, OPERATION_SELECT, OperationRenderable, getSaveOperationsForNote, saveOperation } from "../../../Extensions/ExtensionsFramework/SaveOperationsBasics";
import { Note } from "../../Data/NoteType";
import { Extension } from "../../../Extensions/ExtensionsFramework/ExtensionsList";

type IncludedWhen = "Always" | "As needed" | "EditContext";

interface TableRelatedNotesDataType {
    key: string;
    title: string;
    inclusionType?: IncludedWhen; // optional because the flat list isn't able to check for this
    type: string;
    noteHierarchy: NoteHierarchy;
    children?: TableRelatedNotesDataType[];
}

function getNoteAndChildren(topLevelNoteHierarchy: TopLevelNoteHierarchy, noteHierarchy:NoteHierarchy, notesContext:NotesContextType, parentIDs:string[], inclusionType:IncludedWhen, extensions:Extension[]):TableRelatedNotesDataType {
    // console.log("[getNoteAndChildren] getting noteHierarchy for note: "+noteHierarchy.note.doc_name);
    function getChildrenList(noteIDs:string[], inclusionType:IncludedWhen):TableRelatedNotesDataType[] {
        return noteIDs.map((doc_id:string)=>{
            const childNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[doc_id];
            // There are infinite loops in the list. Don't repeat them.
            if (parentIDs.includes(doc_id))
                return null;
            if (!childNoteHierarchy)
                return null;
            return getNoteAndChildren(topLevelNoteHierarchy, childNoteHierarchy, notesContext, [...parentIDs, doc_id], inclusionType, extensions);
        }).filter((x)=>x!==null) as TableRelatedNotesDataType[];
    }

    let childrenList = getChildrenList(noteHierarchy.fullyIncludedNoteIDs, "Always");
    childrenList = childrenList.concat(getChildrenList(noteHierarchy.linkedNoteIDs, "As needed"));
    childrenList = childrenList.concat(getChildrenList(noteHierarchy.editContextNotes, "EditContext"));    

    const children = childrenList.length>0 ? childrenList : undefined;
    return {
        key: noteHierarchy.note.id,
        title: getNoteNameAndEmoji_multipurpose(noteHierarchy.note, notesContext, extensions).note_title,
        type: noteHierarchy.note.type,
        inclusionType,
        noteHierarchy,
        children,
    };
}

type ListType = "Flat" | "Tree by inclusion";

// const FlatOnlyWhatToShow = ["Completed Notes","Selected","Templates"] as WhatToShowType[];
const SupportsTreeWhatToShow = ["Related Notes"];

const DEBUG_PINNED_AND_SELECTED_SECTION = false;

export function OperationsButtons({note}:{note:Note}) {
    const pinnedJSONFormsContext = useContext(PinnedJSONFormsContext);
    const navigateToNote = useNavigateToNote();
    const {extensions} = useContext(NoteHierarchyContext);
    
    const operations = getSaveOperationsForNote(extensions, pinnedJSONFormsContext,note);
    // Order it so "Undo" or remove is last.
    operations.sort((a:OperationRenderable,b:OperationRenderable) => {
        if (a.add)
            return -1;
        if (b.add)
            return 1;
        return 0;
    });
    if (!operations || operations.length===0) return <></>;
    function saveToTypeAction(operationIndex:number) {
        saveOperation(extensions, pinnedJSONFormsContext,note,operations[operationIndex], navigateToNote);
    }
    return <>{operations.map((operation:OperationRenderable,operationIndex:number) => {
        let icon = <SaveOutlined key={operationIndex}/>;
        if (operation.operation===OPERATION_COMPLETE)
            icon = <CheckCircleOutlined key={operationIndex}/>;
        else if (operation.operation===OPERATION_SELECT)
            icon = <SaveOutlined key={operationIndex}/>;
        return <Button type="link" icon={icon} onClick={()=>saveToTypeAction(operationIndex)} key={operationIndex}>{operation.text}</Button>;
    })}</>;
}

function PinnedAndSelectedSection() {
    const linkToNote = useLinkToNote();
    const pinnedJSONFormsContext = useContext(PinnedJSONFormsContext);
    const pinnedNote = pinnedJSONFormsContext.note;    
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const selectedNote = selectedJsonFormsContext.note;

    function pinNoteAction() {
        if (!pinnedJSONFormsContext.setNoteIdForContext)
            throw new Error("This error should be impossible to reach. It's here for TypeScript.");
        pinnedJSONFormsContext.setNoteIdForContext(selectedNote.id);
    }
    function unpinNoteAction() {
        if (!pinnedJSONFormsContext.setNoteIdForContext)
            throw new Error("This error should be impossible to reach. It's here for TypeScript.");
        pinnedJSONFormsContext.setNoteIdForContext("");
    }
    function UnpinButton() {
        return <Button type="link" icon={<PushpinFilled />} onClick={unpinNoteAction}>Unpin</Button>;
    }

    return <>
        {/* Pinned Note: */}
        {pinnedNote && <div>
                    Pinned: &nbsp;&nbsp;
                    {pinnedNote?.id!==selectedNote?.id && linkToNote(pinnedJSONFormsContext.note)}
                    {pinnedNote?.id===selectedNote?.id && pinnedJSONFormsContext.note.doc_name}
                    {UnpinButton()}
                {!pinnedJSONFormsContext.note && <><Spin/>{DEBUG_PINNED_AND_SELECTED_SECTION && "loading relatedNotesInfo.note"}</>}
            </div>}
        {/* If the selected note is not pinned: */}
        {(!pinnedNote || pinnedNote?.id!==selectedNote?.id) && selectedNote && 
                <div>
                    Selected: &nbsp;&nbsp;
                    {linkToNote(selectedNote)}
                    {/* Offer to pin it: */}
                    {!pinnedNote && /*(pinnedNote?.id!==selectedNote?.id) &&*/
                    <Tooltip title={"Pin '"+selectedNote?.doc_name+"' and its related info to AI chat. Then you can click into other notes while still chatting & focusing on this note."}>
                        <Button type="link" icon={<PushpinOutlined />} onClick={pinNoteAction}>Pin</Button>
                    </Tooltip>}
                    {/* Offer to save it: */}
                    <OperationsButtons note={selectedNote} />
                </div>
        }
        {((!selectedJsonFormsContext.isJSONFormsLoaded && selectedJsonFormsContext.hasNote) || (!pinnedJSONFormsContext.isJSONFormsLoaded && pinnedJSONFormsContext.hasNote)) && <><Spin/>{DEBUG_PINNED_AND_SELECTED_SECTION && "loading JSON forms context"}</>}
    </>;
}


export function RelatedNotesLargePanel() {
    const {topLevelNoteHierarchy, isLoaded, percentLoadingComplete, extensions} = useContext(NoteHierarchyContext);
    const navigateToNote = useNavigateToNote();
    const notesContext = useContext(NotesContext);
    const [whatToShow, setWhatToShow] = useStateLSString<string>("relatedNotesPanel_whatToShow","Related Notes");
    const [listType_internal, setListType] = useStateLSString<ListType>("relatedNotesPanel_listType","Flat");

    const listType = SupportsTreeWhatToShow.includes(whatToShow) ? listType_internal : "Flat";

    const {tableDataSource, distinctTypes} = useMemo(()=>{            
        if (!topLevelNoteHierarchy?.isLoaded) return {tableDataSource:[], distinctTypes: []};
        const tableDataSource = [] as TableRelatedNotesDataType[];
        const distinctTypes = [] as string[];

        for (const noteHierarchy of Object.values(topLevelNoteHierarchy.noteHierarchiesById)) {
            if (!distinctTypes.includes(noteHierarchy.note.type))
                distinctTypes.push(noteHierarchy.note.type);
            if (listType==="Flat") {
                if (whatToShow==="Selected") {
                    // Check the selections. Also include the top level notes and their selections
                    let foundASelected = false;
                    for (const topNoteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
                        if (topNoteHierarchy.selectedNoteIDs.includes(noteHierarchy.note.id)) {
                            foundASelected=true;
                            break;
                        }
                    }
                    if (!foundASelected)
                        continue;
                } else if (whatToShow==="Completed Notes") {
                    // Check the selections. Also include the top level notes and their selections
                    let foundACompleted = false;
                    for (const topNoteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
                        if (topNoteHierarchy.completedNoteIDs.includes(noteHierarchy.note.id)) {
                            foundACompleted=true;
                            break;
                        }
                    }
                    if (!foundACompleted)
                        continue;
                } else if (whatToShow==="Templates") {
                    const matches = isATemplateOrType(noteHierarchy.note)
                    if (!matches)
                        continue;
                }
                tableDataSource.push({
                    key: noteHierarchy.note.id,
                    title: getNoteNameAndEmoji_multipurpose(noteHierarchy.note, notesContext, extensions).note_title,
                    type: noteHierarchy.note.type,
                    noteHierarchy: noteHierarchy,
                });
            }
        }
        if (listType==="Tree by inclusion") {
            // Check how many are selected/pinned. If just one, we show only children, not the parent, in the list.
            if (topLevelNoteHierarchy.topNoteHierarchies.length===1) {
                // Don't push the original on. It's already listed at the top as the context.
                const tableRelatedNotes = getNoteAndChildren(topLevelNoteHierarchy, topLevelNoteHierarchy.topNoteHierarchies[0], notesContext, [], "Always", extensions);
                if (tableRelatedNotes.children)
                    tableDataSource.push(...tableRelatedNotes.children);
            } else {
                // Include all of them
                // Hierarchical list:
                for (const noteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
                    tableDataSource.push(getNoteAndChildren(topLevelNoteHierarchy, noteHierarchy, notesContext, [], "Always", extensions));
                }
            }

        }
        // console.log("tableDataSource",dataSource);
        return {tableDataSource, distinctTypes};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[topLevelNoteHierarchy?.noteHierarchiesById, topLevelNoteHierarchy?.isLoaded,listType, whatToShow]);

    const tableColumns:TableProps<TableRelatedNotesDataType>['columns'] = useMemo(()=>{
        const tableColumnsInner = [{
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => <Button type="link" onClick={()=>navigateToNote(record.noteHierarchy.note.id)}>{text}</Button>,
                sorter: (a, b) => a.title.localeCompare(b.title),
            }, {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: 150,
                filters: distinctTypes.map((type)=>({text:type,value:type})),
                onFilter: (value, record) => record.type === value,
                sorter: (a, b) => a.type.localeCompare(b.type),
            }
        ] as TableProps<TableRelatedNotesDataType>['columns'];
        if (listType==="Tree by inclusion")
            //@ts-ignore tableColumnsInner is never undefined. What are you thinking, TypeScript?
            tableColumnsInner.push({
                title: 'When',
                dataIndex: 'inclusionType',
                key: 'inclusionType',
                width: 150,
                filters: [
                    {text:"Always",value:"Always"},
                    {text:"As needed",value:"As needed"},
                    {text:"EditContext",value:"EditContext"},
                ],
                onFilter: (value, record) => record.inclusionType === value,
                //@ts-ignore inclusionType is never undefined when listType is Tree
                sorter: (a, b) => a.inclusionType.localeCompare(b.inclusionType),
            });
        return tableColumnsInner;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[distinctTypes, listType]);


    const {segmentedOptions,relatedComponents} = useMemo(()=>{
        const segmentedOptions = [
            {value:"Related Notes",label: "Related Notes", icon:<AppstoreOutlined />},
            {value:"Selected", label: "Saved Notes", icon:<SelectOutlined />},
            {value:"Completed Notes", label:"Completed Notes", icon:<CheckCircleOutlined/>},
            {value:"Templates", label:"Templates", icon:<CopyOutlined />},
        ] as SegmentedOptions<string>;
        const relatedComponents = {} as {[key:string]:()=>JSX.Element};
        for (const extension of extensions) {
            for (const relatedListType of extension.relatedListTypes) {
                segmentedOptions.push({                    
                    value: relatedListType.name,
                    label: relatedListType.name,
                    // icon: extension.icon,
                });
                relatedComponents[relatedListType.name] = relatedListType.listComponent;
            }
        }
        return {segmentedOptions, relatedComponents};
    },[extensions]);

    const extensionComponentInsteadOfTable = relatedComponents[whatToShow];

    // console.log("[RelatedNotesLargePanel] isLoaded=",isLoaded,"topLevelNoteHierarchy=",topLevelNoteHierarchy);

    return <div className="related-notes-large-panel">
        <h3>Context</h3>
        <PinnedAndSelectedSection/>
        <br/>
        {!isLoaded && topLevelNoteHierarchy && <Progress type="circle" percent={Math.round(percentLoadingComplete*100)}/>}
        {topLevelNoteHierarchy?.isLoaded && <>
            <Segmented value={whatToShow} onChange={setWhatToShow} options={segmentedOptions}/>
            {SupportsTreeWhatToShow.includes(whatToShow) && <Segmented value={listType} onChange={setListType} options={[
                {value:"Flat", icon: <UnorderedListOutlined />},
                {value:"Tree by inclusion", icon: <ApartmentOutlined />}]}/>}
            {extensionComponentInsteadOfTable && <div className="extensionComponentInsteadOfTable">{extensionComponentInsteadOfTable()}</div>}
            {!extensionComponentInsteadOfTable && <Table columns={tableColumns} dataSource={tableDataSource} scroll={{y:"calc( 100vh - 300px )"}} size="small" pagination={false}/>}
        </>}
    </div>;
}